import React from 'react'
import config from '../config/index.json';

export default function footer(){

  const { footer } = config;

    return(
        <footer className="w-full p-6 relative">
            <div  className=" border-t-[1px] border-black flex justify-between">
                <div className="flex justify-around flex-col h-full">
                    <img alt='inLeges-Logo' className="h-12 w-12 sm:h-20 sm:w-20 mt-2" src={footer.logo}/>
                    <p style={{opacity: .5}} className="text-sm sm:text-lg">
                        @{new Date().getFullYear()} {footer.company}
                    </p>
                </div>
                <div className="flex justify-around flex-col">
                    <div className='flex flex-col items-center'>
                        <p className="text-sm sm:text-lg">{footer.local}</p>
                        <p className="text-sm sm:text-lg">{footer.phone}</p>
                    </div>
                    {/* <div className='flex flex-col justify-between'>
                        <div className=' flex justify-around w-full'>
                            <div className="w-6 h-6 sm:w-8 sm:h-8">
                                <img classNam="w-6 h-6 sm:w-8 sm:h-8" src={footer.facebook}/>
                            </div>
                            <div className="w-6 h-6 sm:w-8 sm:h-8">
                                
                                <img className='w-6 h-6 sm:w-8 sm:h-8' src={footer.linkedin}/>
                            </div>
                            <div className="w-6 h-6 sm:w-8 sm:h-8">
                                
                                <img className='w-6 h-6 sm:w-8 sm:h-8' src={footer.instagram}/>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </footer>
    )
}