import React from 'react'
import Header from '../Parts/header'
import Footer from '../Parts/footer'
import Main from '../Parts/main'
import Explication from '../Parts/explication'

export default function Home () {
    return(
        <div className={`bg-background grid grid-rows-auto grid-cols-1 gap-y-0 `}>
            <Header/>
            <Main/>
            
            <Explication />
            <Footer />
        </div>
    )
}