import React from 'react'

export default function Main(){
    return(
        <div className='h-full w-full'>
            <section className="flex flex-col h-full">
                <p className='font-["Playfair_Display"] text-2xl sm:text-4xl ml-10'>
                    Funcionamento Explicado:
                </p>
                <div className='font-["Roboto"] text-xl text-center text-white p-[3%] flex justify-around w-full'>
                    <div className='bg-[#192231] rounded-l-2xl w-full h-full grid grid-rows-3 flex items-center justify-center'>                    
                        <h1 className='text-4xl sm:text-[50px] font-bold font-["Prata"]'>
                            1
                        </h1>
                        <p className='text-sm sm:text-2xl font-["Prata"]'>
                            Faça um cadastro simplificado
                        </p>
                    </div>
                    <div className='bg-[#3B4B59] w-full h-full grid grid-rows-3 flex items-center justify-center'>
                        <h1 className='text-4xl sm:text-[50px] font-bold font-["Prata"]'>
                            2
                        </h1>
                        <p className='text-sm sm:text-2xl font-["Prata"]'>
                            Responda até 10 perguntas
                        </p>
                    </div>
                    <div className='bg-[#6D7993] w-full h-full grid grid-rows-3 flex items-center justify-center'>
                        <h1 className='text-4xl sm:text-[50px] font-bold font-["Prata"]'>
                            3
                        </h1>
                        <p className='text-sm sm:text-2xl font-["Prata"]'>
                            Encaminhe suas respostas para nosso time de especialistas
                        </p>
                    </div>
                    <div className='bg-[#9099A2] rounded-r-2xl w-full h-full grid grid-rows-3 flex items-center justify-center'>
                        <h1 className='text-4xl sm:text-[50px] font-bold font-["Prata"]'>
                            4
                        </h1>
                        <p className='text-sm sm:text-2xl font-["Prata"]'>
                            Receba em seu e-mail propostas de soluções para seu problema
                        </p>
                    </div>
                </div>
            </section>
        </div>
    )
}