import MainScreen from './Components/Screens/Home'
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<MainScreen />} />
      </Routes>
    </Router>
  );
}

export default App;
