import React from 'react'

import config from '../config/index.json';

export default function Main(){

    const {examples} = config;
    const [firstItem, secondItem, tirdItem, fourthItem] = examples.items;
    return(
        <div className='flex flex-col items-center justify-center grid grid-rows-1 grid-cols-auto sm:grid-cols-1 sm:grid-rows-1 w-full h-[92vh]'>
            <section className='grid grid-cols-1 grid-rows-4 sm:grid-rows-1 sm:grid-cols-2 h-full'>
                <div className="flex flex-col justify-center items-center w-full p-2 row-span-2 h-full row-start-2 sm:row-start-1">
                    <div className='w-4/5'>

                        <p className='text-center font-["Playfair_Display"] text-2xl sm:text-4xl font-bold'>
                            Encontre rapidamente uma resposta para seu problema jurídico
                        </p>
                        <p className='text-center font-["Prata"] text-lg sm:text-xl mt-4'>
                            Para encontrar uma resposta é fácil! Basta clicar no botão abaixo e responder até 10 perguntas!
                        </p>
                        <button type="button"
        onClick={(e) => {
        e.preventDefault();
        window.location.href='https://j215fs2poxm.typeform.com/to/y65ZMi3M'
        }} target="_blank" className='w-full h-16 bg-[#192231] text-white font-[Roboto] text-xl sm:text-2xl rounded-lg font-bold mt-10'>Clique aqui para uma solução</button>
                    </div>
                </div>
                <div className="w-full flex justify-center items-center invisible absolute sm:visible z-0 sm:relative overflow-clip">
                    <div className="w-3/4 flex flex-col justify-center grid grid-cols-auto grid-rows-2">
                        <div className='flex justify-center'>
                            <div className='content-center w-full grid grid-cols-auto grid-rows-5 p-1' >
                                <p className='font-[Prata] text-2xl justify-center items-end flex '>{firstItem?.title}</p>
                                <img alt='contrato-inLeges-problem' src={firstItem?.image} className='rounded-2xl border-2 row-span-4 h-full border-solid'/>  
                            </div>
                            <div className='content-center w-full grid grid-cols-auto grid-rows-5 p-1'>
                                <p className='font-[Prata] text-2xl justify-center items-end flex '>{secondItem?.title}</p>
                                <img alt='heranca-inLeges-problem' src={secondItem?.image}  className='rounded-2xl border-2 row-span-4 h-full border-solid'/>  
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <div className='content-center w-full grid grid-cols-auto grid-rows-5 p-1'>
                                <p className='font-[Prata] text-2xl justify-center items-end flex mt-0'>{tirdItem?.title}</p>
                                <img alt='acidente-inLeges-problem' src={tirdItem?.image} className='rounded-2xl border-2 row-span-4 h-full border-solid'/>  
                            </div>
                            <div className='content-center w-full grid grid-cols-auto grid-rows-5 p-1'>
                                <p className='font-[Prata] text-2xl justify-center items-end flex '>{fourthItem?.title}</p>
                                <img alt='relacionamento-inLeges-problem'src={fourthItem?.image}  className='rounded-2xl border-2 row-span-4 h-full border-solid'/>  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}